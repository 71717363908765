import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/login-otp-verification",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/LogIn/loginOtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/changePassword",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Change Password/Index")),
  },
  {
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  
  {
    exact: true,
    path: "/user-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/index.js")),
  },
  {
    exact: true,
    path: "/tab-user-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/UserMainTab")),
  },
  {
    exact: true,
    path: "/view-user-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/ViewUser.js")),
  },
  {
    exact: true,
    path: "/banner-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/BManagement/BannerManagement")
    ),
  },
  {
    exact: true,
    path: "/ViewHistory",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Reward/ViewHistory")
    ),
  },
  {
    exact: true,
    path: "/kyc-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Kyc/index")
    ),
  },
  {
    exact: true,
    path: "/view-kyc",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Kyc/ViewKyc")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/wallet/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/refferal-setting",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Reward/RefferalSetting")
    ),
  },
  {
    exact: true,
    path: "/promotional-setting",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Reward/PromotionalSetting")
    ),
  },
  {
    exact: true,
    path: "/add-promotion",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Reward/PromotionForm")
    ),
  },

  {
    exact: true,
    path: "/edit-promotion",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Reward/EditPromotion")
    ),
  },
  {
    exact: true,
    path: "/view-refferal",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Reward/ViewRefferal")
    ),
  },
  {
    exact: true,
    path: "/Reward",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Reward/Reward")
    ),
  },
  {
    exact: true,
    path: "/view-banner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BManagement/ViewBanner")),
  },
  {
    exact: true,
    path: "/add-banner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/BManagement/AddBanner")),
  },
  {
    exact: true,
    path: "/edit-banner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BManagement/EditBanner")),
  },

  {
    exact: true,
    path: "/notifications",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notifications/index")),
  },


  {
    exact: true,
    path: "/content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/contentManagement")),
  },
  {
    exact: true,
    path: "/terms&condition",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/terms&Condition/viewContent")),
  },
  {
    exact: true,
    path: "/edit-terms&condition",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/terms&Condition/editContent")),
  },
  {
    exact: true,
    path: "/blog-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BlogManagement/index")),
  },
  {
    exact: true,
    path: "/transaction-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/transaction/index")),
  },
  {
    exact: true,
    path: "/wallet-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/wallet/index")),
  },
  {
    exact: true,
    path: "/view-transaction",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/transaction/viewTransaction")),
  },
  {
    exact: true,
    path: "/withdraw-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Withdraw/Withdraw")),
  },
  {
    exact: true,
    path: "/withdraw-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Withdraw/ViewWithdrawTab")),
  },
  {
    exact: true,
    path: "/view-Deposite",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/transaction/viewDeposite")
    ),
  },
  {
    exact: true,
    path: "/view-withdrawal",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/transaction/viewWithdraw")
    ),
  },
  {
    exact: true,
    path: "/feedback-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Feedback/index")),
  },
  {
    exact: true,
    path: "/view-feedback",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Feedback/viewFeedback")),
  },
  {
    exact: true,
    path: "/glossary",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/glossary/index")),
  },
  {
    exact: true,
    path: "/view-glossary",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/glossary/viewGlossary.js")),
  },
  {
    exact: true,
    path: "/add-blog",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BlogManagement/AddBlog")),
  },
  {
    exact: true,
    path: "/edit-blog",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BlogManagement/EditBlog")),
  },
  
  {
    exact: true,
    path: "/view-blog",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BlogManagement/ViewBlog.js")),
  },

  {
    exact: true,
    path: "/learn-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LearnManagement/index")),
  },
  {
    exact: true,
    path: "/add-learn",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LearnManagement/AddLearn")),
  },
  {
    exact: true,
    path: "/view-learn",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LearnManagement/ViewLearn.js")),
  },
  {
    exact: true,
    path: "/edit-learn",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/LearnManagement/EditLearn")),
  },
    {
    exact: true,
    path: "/my-profile",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyProfile/index.js")),
  },
  {
    exact: true,
    path: "/available-property",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AvailableProperty/index.js")),
  },
  {
    exact: true,
    path: "/view-property",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AvailableProperty/viewProperty")),
  },



  {
    exact: true,
    path: "/contact-us-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contact/contactManagement")),
  },
  {
    exact: true,
    path: "/view-contact-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/contact/viewContact")),
  },
  {
    exact: true,
    path: "/investor-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/InvesterManagement/index")),
  },

 

  {
    exact: true,
    path: "/view-investor-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/propertyInvestment/viewById")),
  },
  {
    exact: true,
    path: "/add-property-share",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AvailableProperty/addshare")),
  },
  {
    exact: true,
    path: "/add-property",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AvailableProperty/addProperty")),
  },
  {
    exact: true,
    path: "/sub-admin-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/SubAdmin/SubAdmin")),
  },
  {
    exact: true,
    path: "/add-sub-admin",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/SubAdmin/Add-SubAdmin")),
  },
  {
    exact: true,
    path: "/view-sub-admin",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/SubAdmin/View-SubAdmin")),
  },
  {
    exact: true,
    path: "/edit-sub-admin",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/SubAdmin/Edit-SubAdmin")),
  },
  {
    exact: true,
    path: "/add-financial-data",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AvailableProperty/addFinancial")),
  },
  {
    exact: true,
    path: "/add-amenities",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AminitiesManagement/Aminities")),
  },
  {
    exact: true,
    path: "/edit-amenities",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AminitiesManagement/EditAminities")),
  },
  {
    exact: true,
    path: "/amenities-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AminitiesManagement/MainAminities")),
  },
  {
    exact: true,
    path: "/faq",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faq")),
  },
  {
    exact: true,
    path: "/view-faq",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faq/viewFaq")),
  },
  {
    exact: true,
    path: "/edit-faq",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faq/editFaq")),
  },
  {
    exact: true,
    path: "/add-faq",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/faq/addFaq")),
  },
  {
    exact: true,
    path: "/funded-properties",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/funded")),
  },
  {
    exact: true,
    path: "/view-funded",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/funded/ViewFunded")
    ),
  },
  {
    exact: true,
    path: "/exited-properties",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ExitedProperty")),
  },
  {
    exact: true,
    path: "/view-exited",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ExitedProperty/ViewExited")),
  },
  {
    exact: true,
    path: "/investor-summary",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ExitedProperty/ViewSummary")),
  },
  {
    exact: true,
    path: "/property-investment",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/propertyInvestment")),
  },
  {
    exact: true,
    path: "/view-each-investor",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/propertyInvestment/viewInvestmentbyId")),
  },
  {
    exact: true,
    path: "/view-investor-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/propertyInvestment/viewById")),
  },
  {
    exact: true,
    path: "/edit-property",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AvailableProperty/editProperty")),
  },
  {
    exact: true,
    path: "/help-support",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/helpSupport")),
  },
  {
    exact: true,
    path: "/view-help-support-data",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/helpSupport/viewById")),
  },
  {
    exact: true,
    path: "/edit-glossary",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/glossary/editGlossary")),
  },
  {
    exact: true,
    path: "/add-glossary",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/glossary/AddGlossary")),
  },
  {
    exact: true,
    path: "/how-it-works",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/howItWorks/edit")),
  },
  {
    exact: true,
    path: "/view-how-it-works",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/howItWorks/viewById")),
  },
  {
    exact: true,
    path: "/edit-how-it-works",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/howItWorks/edit")),
  },
  {
    exact: true,
    path: "/add-how-it-works",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/howItWorks/add")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
