/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Avatar, makeStyles,Button } from "@material-ui/core";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: "pointer",
    position: "absolute",
    right: "52px",
    zIndex: "1",
    width: 50,
    height: 53,
    backgroundColor: "transparent !important",
  },
  avatarBig: {
    cursor: "pointer",
    width: 70,
    height: 70,
  },
  name: {
    fontFamily: "Cambria",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.02em",
    textAlign: "end",
    color: "#000000",
  },
  email: {
    fontFamily: "Cambria",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.02em",
    color: "#000000",
    textAlign: "end",
  },
  editButton: {
    color: "#fff",
    fontSize: "15px",
    fontStyle: "normal",
    fontFamily: "Cambria",
    fontWeight: "700",
    letterSpacing: "0.02em",
  },
  userButton: {
    width: "143.21px",
    height: "44.7px",
    background: "#F2F5FA",
    borderRadius: "27px",
    display: "flex",
    justifyContent: "space-around",
    paddingLeft: "50px",
    fontFamily: "Cambria",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.02em",
    color: "#000000",
  },
  logoutButton: {
    position: "absolute",
    right: "52px",
    zIndex: "1",
    backgroundColor: "#fae8e2 !important",
    borderRadius: "6px",
    border:'1px solid #f6d2d2'
  },
  logoutIcon: {
    color: "#FF1212 !important",
    fontSize: "24px",
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const confirmationHandler = () => {
    history.push("/");
    window.localStorage.removeItem("token");
    window.localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <>
      <Button
        variant="contained"
        
        className={classes.logoutButton}
        onClick={() => setOpen(true)} // Directly opens Confirmation Dialog
      >
        <ExitToAppIcon className={classes.logoutIcon} />
      </Button>


      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
        />
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
