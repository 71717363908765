import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FiSun } from "react-icons/fi";
import SettingsContext from "src/context/SettingsContext";
import { FaRegMoon } from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
  content: {
    height: "fit-content",
    // width: "100%",
    overflowY: "auto",
    maxWidth: "500px",
    margin: "0 auto",
    borderRadius: "33px",
    paddingTop: "17px",
    paddingBottom: "27px",
    color: "#1A1919",
    backgroundColor: "#121212",
    paddingLeft: "50px",
    paddingRight: "50px",
    "&::-webkit-scrollbar": {
      
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      margin: "40px 0 40px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      background:
        "linear-gradient(180deg, rgba(47, 245, 255) 0%, rgba(2, 175, 184) 100%);",
    },
  },
  left: {
    height: "100%",
    "@media(max-width:959px)": {
      display: "none",
    },
  },


  logoImage: {
    position: "absolute",
    zIndex: "1",
    top: "20px",
    maxWidth: "225px",
    cursor: "pointer",
    "@media(max-width:1279px)": {
      display: "none",
    },
  },
  mainScreen: {
    maxWidth: "100%",
    /* background-repeat: no-repeat; */
    minHeight: "100vh",
    // backgroundImage: `url(${process.env.PUBLIC_URL}/images/login/loginBackground.png)`,
    background: "#e8e8e8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 916px)": {
      padding: "16px",
    },
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  logosec: {
    textAlign: "center",
    paddingTop: "8px",
    paddingBottom:"20px",

  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = React.useContext(SettingsContext);

  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const [themeChange, seThemeChange] = useState("");
  useEffect(() => {
    const activeTheme = themeSeeting?.themekey?.theme;
    seThemeChange(activeTheme);
  }, []);
  return (
    <Box>
      <Grid container className={classes.mainScreen}>
        <Grid item xs={12}>
          <Box className={classes.content}>
            <Box className={classes.logosec}>
              {/* <img style={{ width: "143px" }} src="images/investment_app.jpeg" /> */}
            </Box>
            {children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
