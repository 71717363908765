import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";

const sections = [
  { title: "Dashboard", icon: "📊", href: "/dashboard" },
  {
    title: "Management",
    items: [
      {
        title: "Sub -Admin Management",
        href: "/sub-admin-management",
        icon: "👤",
      },
      // {
      //   title: "Investor Management",
      //   href: "/investor-management",
      //   icon: "👥",
      // },
      { title: "User Management", href: "/user-management", icon: "🧑‍💻" },
      { title: "KYC Management", href: "/kyc-management", icon: "🔒" },
      {
        title: "Amenities Management",
        href: "/amenities-management",
        icon: "🏊",
      },
    ],
  },
  {
    title: "Properties",
    items: [
      { title: "Active Property", href: "/available-property", icon: "🏠" },
      { title: "Funded Property", href: "/funded-properties", icon: "🏘️" },
      {
        title: "Property Investment",
        href: "/property-investment",
        icon: "📊",
      },
      { title: "Exited Property", href: "/exited-properties", icon: "📉" },
    ],
  },
  {
    title: "Financial Management",
    items: [
      { title: "Withdraw", icon: "💸", href: "/withdraw-management" },
      { title: "Transactions", icon: "💳", href: "/transaction-management" },
      { title: "Wallet ", icon: "💼", href: "/wallet-management" },
    ],
  },
  {
    title: "Content",
    items: [
      { title: "Content Management", href: "/content-management", icon: "📝" },
      { title: "Learn Management", href: "/learn-management", icon: "📚" },
      { title: "Blogs Management", href: "/blog-management", icon: "✍️" },
    ],
  },
  {
    title: "Feedback & Rewards",
    items: [
      {
        title: "Feedback Management",
        href: "/feedback-management",
        icon: "💬",
      },
      { title: "Reward Management", href: "/Reward", icon: "🏆" },
    ],
  },
  {
    title: "Help & Settings",
    items: [
      { title: "Glossary", href: "/glossary", icon: "📖" },
      { title: "Help & Support", href: "/help-support", icon: "❓" },
      { title: "Change Password", href: "/changePassword", icon: "🔑" },
    ],
  },
];

const useStyles = makeStyles(() => ({
  drawer: {
    width: 267,
    background: "#121212",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  collapsed: {
    width: 80,
    overflow: "hidden",
  },
  menuSection: {
    marginBottom: "0px",
  },
  menuTitle: {
    color: "rgb(107, 201, 247)",
    fontSize: "14px",
    padding: "10px 15px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 15px",
    textDecoration: "none",
    color: "#fff",
    width: "100%",
    marginBottom: '2px',
    transition: "background 0.3s",
    "&:hover": {
      background: "#ddddd8",
      color: "#121212",
    },
  },
  icon: {
    marginRight: "10px",
    fontSize: "20px",
  },
  logo: {
    borderRadius: "15px",
    maxWidth: "50%",
    height: "auto",
    padding: "30px",
    background: "white",
  },
  scrollable: {
    flexGrow: 1,
    overflowY: "auto",
    maxHeight: "100vh",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const userType = sessionStorage.getItem("userType");

  // Filter sections based on permissions
  const getDecryptedPermissions = () => {
    const encryptedPermissions = sessionStorage.getItem("permissions");

    if (!encryptedPermissions) return [];

    try {
      const bytes = CryptoJS.AES.decrypt(
        encryptedPermissions,
        process.env.REACT_APP_CRYPTO_SECRET_KEY
      );
      const decryptedPermissions = JSON.parse(
        bytes.toString(CryptoJS.enc.Utf8)
      );
      return decryptedPermissions || [];
    } catch (error) {
      console.error("Error decrypting permissions:", error);
      return [];
    }
  };

  // Filter sections based on permissions
  const getFilteredSections = () => {
    // Always include "Dashboard" for the first position and "Help & Settings" for the last
    const dashboardSection = sections.find(
      (section) => section.title === "Dashboard"
    );
    const helpAndSettingsSection = sections.find(
      (section) => section.title === "Help & Settings"
    );

    // Filter out the dashboard and help & settings sections from the rest
    const remainingSections = sections.filter(
      (section) =>
        section.title !== "Dashboard" && section.title !== "Help & Settings"
    );

    // If the userType is ADMIN, no filtering is applied
    if (userType === "ADMIN") {
      return [dashboardSection, ...remainingSections, helpAndSettingsSection];
    }

    const permissions = getDecryptedPermissions(); // Decrypt permissions from sessionStorage

    // Helper function to check if a permission is granted
    const isPermissionGranted = (permissionName) => {
      const permission = permissions.find(
        (perm) => perm.permission === permissionName
      );
      return permission?.isGranted || false;
    };

    // Filter the sections excluding Dashboard and Help & Settings
    const filteredSections = remainingSections
      .map((section) => {
        if (section.items) {
          const filteredItems = section.items.filter((item) =>
            isPermissionGranted(item.title)
          );

          if (filteredItems.length > 0) {
            return { ...section, items: filteredItems };
          }
          return null;
        }

        return isPermissionGranted(section.title) ? section : null;
      })
      .filter(Boolean);

    return [dashboardSection, ...filteredSections, helpAndSettingsSection];
  };

  const filteredSections = getFilteredSections();

  const renderSections = (sections) => (
    <Box className={classes.scrollable}>
      <Box mt={2} mb={2}>
        <a
          href="/dashboard"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src="/images/nesba.svg"
            alt="Investment App Logo"
            className={classes.logo}
          />
        </a>
      </Box>
      {sections.map((section) => (
        <Box key={section.title} className={classes.menuSection}>
          {section.items ? (
            <>
              <Box className={classes.menuTitle}>{section.title}</Box>
              <List>
                {section.items.map((item) => (
                  <Button
                    key={item.title}
                    className={classes.menuItem}
                    onClick={(event) => {
                      event.preventDefault();
                      history.push(item.href);
                    }}
                    style={
                      location.pathname === item.href
                        ? { background: "#ddddd8", color: "#121212" }
                        : undefined
                    }
                  >
                    <span className={classes.icon}>{item.icon}</span>
                    {!isCollapsed && item.title}
                  </Button>
                ))}
              </List>
            </>
          ) : (
            <Button
              key={section.title}
              className={classes.menuItem}
              onClick={(event) => {
                event.preventDefault();
                history.push(section.href);
              }}
              style={
                location.pathname === section.href
                  ? { background: "#ddddd8", color: "#121212" }
                  : undefined
              }
            >
              <span className={classes.icon}>{section.icon}</span>
              {!isCollapsed && section.title}
            </Button>
          )}
        </Box>
      ))}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box className={classes.drawer}>
            {renderSections(filteredSections)}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: `${classes.drawer} ${isCollapsed ? classes.collapsed : ""}`,
          }}
          open
          variant="persistent"
        >
          <Box className={classes.drawer}>
            {renderSections(filteredSections)}
            <Box className={classes.toggleButton} onClick={handleToggle}>
              {isCollapsed ? "⮞" : "⮜"}
            </Box>
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
